import SquareIcon from '@mui/icons-material/Square';
import { useTheme } from "@mui/material";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { axisClasses } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts/BarChart';
import APIRequest from "Helpers/ApiRequest";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

export default function Results() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = React.useState("");
  const [correct, setCorrect] = React.useState(0);

  async function getData() {
    var hasUrl = questionID != "";
    if (hasUrl){
    var req = await APIRequest({action: "questions_get_results", question_id:questionID, start_time:startTime, end_time:endTime});
    }
    else{
      var req = await APIRequest({action: "questions_get_results", question_id:16});
    }
    if (req.status == true){
      return req.data.results;
    }
    return null;

  }


  React.useEffect(() => {
    async function getNewRows() {
      const row = await getData();
      if (row != null){
        setRows(row);
        setCorrect((row.map((row) => (row['is_correct'] ? 1: 0))).findIndex(x => x == 1)+1);
      }
    }
    getNewRows();
  }, [])

  // setCorrect(isCorrect.findIndex(x => x == 1))

  const [highlighted, setHighlighted] = React.useState('item');
  const [faded, setFaded] = React.useState('global');
  const theme = useTheme();


  var questionID = searchParams.get("question_id") ? searchParams.get("question_id") : "";
  var startTime = searchParams.get("start_time") ? searchParams.get("start_time") : "";
  var endTime = searchParams.get("end_time") ? searchParams.get("end_time") : "";



  // <Typography component="h1" variant="h5" color="text.primary" sx={{fontSize: 40, fontWeight: 800, margin: "10px"}}>{rows[0].question}</Typography>
  return (
    
    <Stack sx={{height:"90vh"}} justifyContent="center" alignItems="center">
    {rows &&
      <Typography component="h1" variant="h5" color="text.primary" sx={{fontSize: 40, fontWeight: 800, margin: "10px"}}>{rows[0].question}</Typography>
    }
  {correct != 0 &&
    <Grid container justifyContent="center">
        <Stack alignItems="center" direction="row" gap={2}>
          <SquareIcon sx={{color: "primary.main"}}/>
          <Typography component="h1" variant="h5" color="text.primary">Correct</Typography>
          <SquareIcon sx={{color: "secondary.main"}}/>
          <Typography component="h1" variant="h5" color="text.primary">Incorrect</Typography>
        </Stack>
    </Grid>
    }

      {rows &&
        <BarChart
          dataset={rows}
          margin={{
            left: 10,
            right: 10,
          }}
          yAxis={[{ 
            scaleType: 'band', 
            dataKey: 'answer', 
            tickLabelStyle: {
              angle: 0,
              textAnchor: 'start',
              fontSize: "2rem",
            }, 
          }]}
          xAxis={[{
            tickMinStep: 1
          }]}
          series= {[{
            data: rows.map((rows) => (rows['num_answers'])),
            highlightScope: {highlighted: 'item', faded: 'global'}
          }]}
          layout="horizontal"
          grid={{"vertical": true}}
          colors={[theme.palette.secondary.main]}
          sx={{
            [`.${axisClasses.left}`]: {transform: 'translateX(10px)'},
            [`.${axisClasses.bottom} .${axisClasses.label}`]: {transform: 'translate(0, 20px)'},
            [`& .MuiBarElement-root:nth-of-type(${correct})`]: {fill: theme.palette.primary.main}, 
            "& .MuiChartsAxis-left text": {fontSize: "2rem", transform: 'translate(20px, 0)'},
            // "& .MuiChartsAxis-label tspan": {fontSize: 24}
            }}
          
        />}

    </Stack>
  );
}
