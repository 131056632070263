import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Collapse from '@mui/material/Collapse';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MainDrawer from "Drawers/MainDrawer";
import theme from "Helpers/theme.js";
import background from "img/Background.jpg";
import conectadoIcon from "img/ConectadoIcon.png";
import PropTypes from 'prop-types';
import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";


const defaultTheme = theme;

const cookies = new Cookies();

export default function AdminLogin({ setToken, setRedirect }) {
  const [err, setError] = React.useState("");
  const [mess, setMessage] = React.useState("Unknown Error");
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const areAllFieldsFilled = email != "" && password != "";
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  var loc = searchParams.get("plc") ? "/?plc=" + searchParams.get("plc") : "";
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')

    var details = {
      action: "login",
      email: data.get("email"),
      password: data.get("password"),
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    var response = await fetch("https://conectadoverse.com/api/api.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    });
    var body = await response.json();
    if (body.status == true) {
      var userID = body.data.account_id;
      var cookieID = body.data.cookie_id;
      var firstName = body.data.firstname;
      var lastName = body.data.lastname;
      cookies.set("user", userID, { path: "/" });
      cookies.set("cookie", cookieID, { path: "/" });
      cookies.set("firstname", firstName, { path: "/" });
      cookies.set("lastname", lastName, { path: "/" });

      if (body.data.admin == 1){
        var redir = searchParams.get("redirect")
        if (redir){
          setRedirect(searchParams.get("redirect"))
        }
        setToken(cookieID);
        handleOpen();
        setError("success");
        setMessage("Sign-in Successful");
      }
      else{
        setError("error");
        setMessage("Not Enough Permissions");
      }
    } else {
      setError("error");
      setMessage(body.message);
    }
  };

  return (
    <div style={{ 
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh',
      margin: 0,
      top: 0,
      left: 0,
      }}>
      <div style={{ 
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh',
      margin: 0,
      position: 'fixed',
      top: 0,
      left: 0,
      }}
      ></div>
      <Grid container
      direction="row"
      justifyContent="center"
      alignItems="center"
      position="sticky"
      sx={{display: "flex", height: "100vh"}}
      >
        <Box sx={{ my: 'auto', padding: "20px" ,backgroundColor: 'background.paper', borderRadius: '25px', width:"30rem", boxShadow: '5px 5px 50px #0000004D'}}>
    <Box theme={defaultTheme}>
    <MainDrawer/>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "6rem",
              height: "6rem",
              marginBottom: "1rem",
              boxShadow: "5px 10px 20px #0000000F",
              borderRadius: "15px",
            }}
          >
            <div
              alt="Conectado"
              style={{
                backgroundImage: `url(${conectadoIcon})`,
                width: "4rem",
                height: "4rem",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Grid>
          <Typography component="h1" variant="h5" sx={{ marginBottom: "1rem" }}>
            Admin Login
          </Typography>
          <Box
            component="form"
            id="loginForm"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <Typography component="h3" variant="h6">
              Email
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
              variant="filled"
              InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 0}}}}
            />
            <Typography component="h3" variant="h6" sx={{ marginTop: "1rem" }}>
              Password
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              variant="filled"
              InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 0}}}}
            />

            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: "1rem" }}
            >
              <Grid item xs>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={<Typography variant="option">Remember me</Typography>}
                />
              </Grid>
              {/* <Grid item>
                {               <Typography component={Link} to="/forgot" variant="option" sx={{textDecoration: 'none', fontWeight: 600, color: "primary.main"}}>
                  Forgot Password
                </Typography> }
              </Grid> */}
            </Grid>
            <Divider sx={{ marginTop: "1rem" }}></Divider>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!areAllFieldsFilled}
              endIcon={<ArrowForwardIcon />}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{ color: "text.secondary", align: "center", fontWeight: 600 }}
            >
              Don't have an account?{" "}
              {
                <Typography
                  component={Link}
                  to={`/register${loc}`}
                  variant="contained"
                  color="primary"
                  sx={{ textDecoration: "none" }}
                >
                  Register
                </Typography>
              }
            </Typography>
          </Box>
        </Box>
        {email=="meet" &&
        <Typography component={Link} to="/meet" variant="option" sx={{textDecoration: 'none', fontWeight: 600, color: "primary.main"}}>
                  Temp Testing
                </Typography>}
      </Container>
      <Collapse in={!open}>
        <Snackbar open={err != "" && !open} autoHideDuration={6000}>
          <Alert severity={err == "" ? "error" : err} sx={{ width: "100%" }} action={            <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>}>
            {mess}
          </Alert>
          
        </Snackbar>
      </Collapse>
    </Box>
    </Box>
      </Grid>
      </div>
  );
}

AdminLogin.propTypes = {
  setToken: PropTypes.func.isRequired,
  setRedirect: PropTypes.func.isRequired
};