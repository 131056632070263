import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Copyright from "Helpers/Copyright";
import useRedirect from "Helpers/useRedirect";
import useToken from "Helpers/useToken";
import background from "img/Background.jpg";
import AdminLogin from "Pages/AdminLogin";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import APIRequest from "Helpers/ApiRequest";
import { publicIp, publicIpv4, publicIpv6 } from "public-ip";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Typography, TextField, Button } from "@mui/material";
import { useSnackbar } from 'notistack';
import ThemeSwitcherComponent from "Helpers/ThemeSwitcherComponent";
import { NeuButton } from "Helpers/NeuButton";
import { Home } from "@mui/icons-material";

export default function BaseApp(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { token, setToken } = useToken();
  const { redirect, setRedirect } = useRedirect();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));


  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    navigate("/");
    navigate(0);
  }

  if (props.isAdmin == true) {
    if (!token) {
      return <AdminLogin setToken={setToken} setRedirect={setRedirect} />;
    }

    if (redirect) {
      localStorage.removeItem("redirect");
      window.location.replace(redirect);
    }
  }

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100vh",
          margin: 0,
          position: "fixed",
          top: 0,
          left: 0,
          ...(!matches && {
            backgroundImage: "none",
            backgroundColor: "background.paper",
          }),
        }}
      ></Box>
    
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        position="sticky"
        sx={{ display: "flex", height: "100vh" }}
      >
      
          <Box
            sx={{
              my: "auto",
              padding: "20px",
              backgroundColor: "background.paper",
              borderRadius: "25px",
              width: props.width,
              boxShadow: "5px 5px 50px #0000004D",
              ...(!matches && {
                height: "100%",
                width: "100%",
                borderRadius: "0px",
              }),
            }}
          >
            {props.Child}
            <Copyright />
          </Box>
        
        {/* {!notBanned && (
          <Box
            sx={{
              my: "auto",
              padding: "20px",
              backgroundColor: "background.paper",
              borderRadius: "25px",
              width: props.width,
              boxShadow: "5px 5px 50px #0000004D",
              ...(!matches && {
                height: "100%",
                width: "100%",
                borderRadius: "0px",
              }),
            }}
          >
            {<Box>Site Under Construction</Box>}

            <Copyright />
          </Box>
        )} */}
      </Grid>
      <NeuButton variant="contained" aria-label="theme-switch" onClick={routeChange} sx={(theme) => ({position:"fixed", top:"1rem", left:"1rem"})}>
        <Home sx={{ color: theme.palette.action.active }} />
      </NeuButton>
      <ThemeSwitcherComponent useDark={theme.palette.mode === "dark"} themeChanger={props.toggleDarkMode} />
    </Box>
  );
}

BaseApp.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  Child: PropTypes.object,
};
