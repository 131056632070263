import * as React from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { purple } from "@mui/material/colors";

export const NeuButton = styled(Button)(({ theme }) => ({
  boxShadow: theme.palette.neuShadow.main,
  background: theme.palette.neuBackground.main,
  textTransform: "none",
  fontSize: 16,
  padding: "10px",
  lineHeight: 1.5,
  borderRadius: "20px",
  padding: "10px",
  minHeight: "0px",
  minWidth: "0px",
  "&:hover": {
    boxShadow: theme.palette.neuShadow.hover,
    background: theme.palette.neuBackground.hover,
  },
  "&:active": {
    boxShadow: theme.palette.neuShadow.hover,
    background: theme.palette.neuBackground.hover,
  },
  "&:focus": {
    boxShadow: theme.palette.neuShadow.hover,
    background: theme.palette.neuBackground.hover,
    },
}));
