import AppleIcon from '@mui/icons-material/Apple';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { Divider } from "@mui/material";
import { Tooltip } from '@mui/material/';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from '@mui/material/Collapse';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { getGPUTier } from 'detect-gpu';
import MainDrawer from "Drawers/MainDrawer";
import theme from "Helpers/theme.js";
import anywaysImg from "img/AnywaysTooltip.png";
import conectadoIcon from "img/ConectadoIcon.png";
import extractImg from "img/ExtractTooltip.png";
import trustedImg from "img/MoreInfoTooltip.png";
import runImg from "img/RunTooltip.png";
import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppIcon from 'Helpers/AppIcon';


export default function Desktop() {

  const driveLink = 'https://conectadoverse.com/download.php?platform=windows';
  const macLink = 'https://conectadoverse.com/download.php?platform=mac';

  const defaultTheme = theme;
  const [err, setError] = React.useState("");
  const [mess, setMessage] = React.useState("Unknown Error");
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(false);
  };

  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogBody, setDialogBody] = React.useState("");
  const [dialogState, setDialogState] = React.useState("Desktop");

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0 || searchParams.get("device") == "mac";
  const isWin = navigator.platform.toUpperCase().indexOf('WIN') >= 0 || searchParams.get("device") == "pc";


  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getGPUTier();
        setData(data);
        if ((data.tier != 3 || data.isMobile == true) && !dialogOpen){
          setDialogOpen(true);
          setDialogBody("Your computer hardware does not meet the minimum requirements to run the Conectado Desktop Version. Your experience may be affected.");
          setDialogTitle("Desktop Requirements not met");
          setDialogState("Speed");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }


    fetchData();
  }, []);



  const [file, setFile] = React.useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const areAllFieldsFilled = file != null;

  var loc = searchParams.get("plc") ? "/?plc=" + searchParams.get("plc") : "";

  const handleSubmit = async (event) => {
    event.preventDefault();
  };


  return (
    <Box>
      <MainDrawer/>
      <Container component="main" maxWidth="xm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
        <AppIcon/>
          <Typography component="h1" variant="h5">
            Desktop Version
          </Typography>
              <Box
                component="form"
                id="loginForm"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, width: "100%" }}
              >
            <Grid>
                <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
                  <Grid item>
                  <Typography component="h3" variant="h7" sx={{ marginTop: "1rem", marginBottom: ".5rem" }}>Minimum Requirements</Typography>
                  <div  sx={{marginLeft: "1rem" }}>{isMac ? (<p>Apple Silicon M1+ <br></br> Mac OS 14.2+</p>) : (<p><strong>GPU: </strong>NVIDIA GeForce GTX 1060 6GB<br></br> <strong>CPU: </strong>Intel Core i7-610<br></br><strong>RAM: </strong>8GB<br></br><strong>SSD: </strong>6GB<br></br><strong>OS: </strong>Windows 10, 11<br></br></p>)}
                  
                  
                  
                  </div>
                  </Grid>
                  <Grid item>
                    <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
                      <Typography component="h3" variant="h7" sx={{ marginTop: "1rem", marginBottom: "1rem" }}>How To Install</Typography>
                      <Typography  sx={{marginLeft: "2rem" }}>
                      
                      <li>Download the Latest Version <Typography
                        component={Link}
                        to={isMac ? macLink : driveLink}
                        variant="contained"
                        color="primary"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: "none" }}
                      ><strong>
                        Here
                        </strong>
                        </Typography>
                      </li>
                    <Tooltip placement="bottom-start" disableInteractive title={<Box component="img" src={extractImg} sx={{maxWidth: '280px', marginTop: '4px'}}></Box>}><li>Extract the Zip file</li></Tooltip>
                    <Tooltip placement="bottom-start" disableInteractive title={<Box component="img" src={runImg} sx={{maxWidth: '280px', marginTop: '4px'}} ></Box>}><li>Run Conectado.exe</li></Tooltip>
                    <Tooltip placement="bottom-start" disableInteractive title={<Box component="img" src={trustedImg} sx={{maxWidth: '280px', marginTop: '4px'}} ></Box>}><li>Click More Info</li></Tooltip>
                    <Tooltip placement="bottom-start" disableInteractive title={<Box component="img" src={anywaysImg} sx={{maxWidth: '280px', marginTop: '4px'}} ></Box>}><li>Click Run Anyway</li></Tooltip>
                    
                    </Typography>
                    <Divider sx={{marginTop: "1rem", marginBottom: "1rem"}}></Divider>
                    <Grid item>
                    <Accordion disableGutters={true} square={true} elevation={0} sx={{padding:"0", background:"none"}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{padding:"0", flexDirection:"row-reverse", '& .MuiAccordionSummary-expandIconWrapper':{color:'text.primary'}}}
                    >
                      <Typography component="h3" variant="h7">FAQ</Typography>
                      </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <ul>
                                  <li>Executable says not trusted
                                    <ul>
                                      <li>
                                      This is normal, please press trust this application
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                            </div>
                      </AccordionDetails>
                    </Accordion>
                      <Divider sx={{marginTop: "1rem", marginBottom: "1rem"}}></Divider>
                      </Grid>
                </Grid>
            </Grid>
          </Box>
          <Typography>
                      
                      Want to use the Web Version? <Typography
                        component={Link}
                        to={`/`}
                        variant="contained"
                        color="primary"
                        sx={{ textDecoration: "none" }}
                      ><strong>
                        Click Here
                        </strong>
                        </Typography>
                  
                    </Typography>
                    <Typography component="h5" variant="h5">Mac version currently unavailable</Typography>
          <Grid>
          <Grid item>
            <Button
                  type="submit"
                  variant="contained"
                  component={Link}
                  to={driveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  endIcon={<MicrosoftIcon />}
                  sx={{paddingX: "5rem", m: 2, width: "40%"}}
                  >
                  Windows
            </Button>
            <Button
                  type="submit"
                  variant="contained"
                  component={Link}
                  to={macLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={true}
                  endIcon={<AppleIcon />}
                  sx={{paddingX: "5rem", m: 2, width: "40%"}}
                  >
                  Mac
            </Button>
            <Typography component="h5" variant="h6">Please Note: Mac version only supports 2020+ models (Apple Silicon M1+ chips) using Mac OS 14.2+</Typography>
          </Grid>
          
          </Grid>
        </Box>
      </Container>
      <Collapse in={!open}>
        <Snackbar open={err != "" && !open} autoHideDuration={6000}>
          <Alert severity={err == "" ? "error" : err} sx={{ width: "100%" }} action={            <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>}>
            {mess}
          </Alert>
          
        </Snackbar>
      </Collapse>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.error.main,
          },
        }}
        
      >
        <DialogTitle id="alert-dialog-title" sx={{color: "#fff"}}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description" sx={{color: "#fff"}}>
            {dialogBody}
          </Typography>
          <br></br>
          <Typography id="alert-dialog-description" sx={{color: "#fff"}} textAlign={"center"}>
          We recommend using the Web Version.
          </Typography>
        </DialogContent>
        <DialogActions>
        
          <Button onClick={handleClose} sx={{color: "#fff"}}>Continue</Button>
          <Button onClick={handleClose} sx={{color: "#fff"}} href="./login" autoFocus>Web Version</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
