import { createTheme } from '@mui/material/styles';

// A custom theme for this app

const theme = createTheme({
  palette: {
    mode:'light',
    primary: {
      light: '#94FFE8',
      main: '#26B99A',
      dark: '#008367'
    },
    secondary: {
      light: '#ffb2ba',
      main: '#F15162',
      dark: '#9b0024'
    },
    dark:{
      main: '#0D1A35',
    },
    text: {
      secondary: '#A1A2A5',
      hint: '#26b99a',
    },
  },

  

  typography: {
    fontFamily: 'Montserrat',
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
      fontSize: 16,
      color: "#A1A2A5",
    },
    button: {
      fontWeight: 1000,
    },
    option:{
      fontWeight: 600,
      fontSize: 14
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' && {
            fontSize: '1rem',
            outline: 'none',
            borderRadius: '40px',
            background: 'linear-gradient(45deg, #94FFE8 -50%, #26B99A 100%)',
            boxShadow: '3px 3px 15px #24415D4D',
            '&:hover': {
              boxShadow: '3px 3px 15px #24415D4D',
            }
            }),
        }),
      }
  },
  button: {
  },
  MuiLink:{
    variants: [
      {
        props: { variant: 'dashed' },
          style: {
            textTransform: 'none',
            border: `2px dashed `,
          },
        }
    ]
  },
  MuiInputBase:{
    styleOverrides: {
      root:{
        borderBottom: 'none',
        disableUnderline: true,
        boxShadow: '5px 5px 10px #00000005'
      }

    }
  },
  
  
  MuiTextField:{
    styleOverrides: {
      root:{
        marginTop: 0,
        borderRadius: '40px',
      }
    }
  },

  MuiFormControlLabel:{
    styleOverrides: {
      root:{
        fontWeight: 600
      }
    }
  },
  MuiCardContent:{
    styleOverrides: {
      root:{
        "&:last-child": {
          paddingBottom: 5,
        }
      }
    }
  }
}
})

export default theme;
