import background from "img/Background.jpg";


export default function AppStatic() {

  return (
    <div style={{ 
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh',
      margin: 0,
      position: 'fixed',
      top: 0,
      left: 0,
      }}>
      </div>
  );
}