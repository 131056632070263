import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';


import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Admin from '../Pages/Admin';
import Desktop from '../Pages/Desktop';
import Device from '../Pages/Device';
import Forgot from '../Pages/Forgot';
import SignIn from '../Pages/Login';
import Mod from '../Pages/Mod';
import Quiz from '../Pages/Quiz';
import Results from '../Pages/Results';
import SignUp from '../Pages/SignUp';
import AppStatic from './AppStatic';
import BaseApp from './BaseApp';
import Cookies from "universal-cookie";
  


export default function App(props) {
  const [password, setPassword] = React.useState("");

  const sendResetPassword = async (event) => {
    event.preventDefault();
    var body = await APIRequest({action:"password_reset", linkid: params["password_reset"], password: password});
    if(body.status == true){
      window.location.replace(window.location.origin+"/login");
    }
    else{
      enqueueSnackbar(body.message, { variant: "error" , preventDuplicate: true});
    }
    setForgotOpen(false);
  };

    const cookies = new Cookies();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const themeCookie = cookies.get("theme");

    const [darkMode, setDarkMode] = React.useState(prefersDarkMode);
    
    
  React.useEffect(() => {
    if (themeCookie === "dark") setDarkMode(true);
    if (themeCookie === "light") setDarkMode(false);
  }, []);
  
  const themeString = (b) => (b ? "dark" : "light");

    const theme = React.useMemo(
        () =>
          createTheme({
            palette: {
              mode: themeString(darkMode),
              primary: {
                light: '#94FFE8',
                main: '#26B99A',
                dark: '#008367'
              },
              secondary: {
                light: '#ffb2ba',
                main: '#F15162',
                dark: '#9b0024'
              },
              dark:{
                main: '#0D1A35',
              },
              text: {
                secondary: '#A1A2A5',
                hint: '#26b99a',
              },
              ...(themeString(darkMode) === 'light') ? 
              {
                neuShadow: {
                  main: "2px 2px 12px #bebebeaa, -2px -2px 12px #ffffffaa",
                  hover: "rgba(50, 50, 93, .1) 0px 6px 6px -8px inset, inset 2px 5px 10px rgba(0,0,0,0.1)",
                },
                neuBackground: {
                  main: "#e0e0e0",
                  hover: "rgba(255, 255, 255, 0.6)"
                },
              }:
              {
                neuShadow: {
                  main: "2px 2px 12px #222aa, -2px -2px 12px #111aa",
                  hover: "rgba(50, 50, 93, .1) 0px 6px 6px -8px inset, inset 2px 5px 10px rgba(0,0,0,0.1)",
                },
                neuBackground: {
                  main: "#222",
                  hover: "#111"
                },
              },
            },
          
            typography: {
              fontFamily: 'Montserrat',
              h5: {
                fontWeight: 600,
              },
              h6: {
                fontWeight: 600,
                fontSize: 16,
                color: "#A1A2A5",
              },
              button: {
                fontWeight: 1000,
              },
              option:{
                fontWeight: 600,
                fontSize: 14
              }
            },
            components: {
              MuiButton: {
                styleOverrides: {
                  // Name of the slot
                  root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'contained' && {
                      fontSize: '1rem',
                      outline: 'none',
                      borderRadius: '40px',
                      
                      background: 'linear-gradient(45deg, #94FFE8 -50%, #26B99A 100%)',
                      boxShadow: '3px 3px 15px #24415D4D',
                      '&:hover': {
                        boxShadow: '3px 3px 12px #24415D4D',
                        outline: 'none',
                      }
                      }),
                  }),
                },
                variants:[
                  {
                    props: {variant: 'neu'},
                    style: {
                      fontSize: '1rem',
                      outline: 'none',
                      padding: "10px",
                      borderRadius: '20px',
                      minHeight: "0px",
                      minWidth: "0px",
                      boxShadow: '-2px -2px 12px #ffffff',
                      backgroundColor: '#e0e0e0',
                    }
                  }
                ]

            },
            button: {
            },
            MuiLink:{
              variants: [
                {
                  props: { variant: 'dashed' },
                    style: {
                      textTransform: 'none',
                      border: `2px dashed `,
                    },
                  }
              ]
            },
            MuiInputBase:{
              styleOverrides: {
                root:{
                  borderBottom: 'none',
                  disableUnderline: true,
                  boxShadow: '5px 5px 10px #00000005'
                }
          
              }
            },
            
            
            MuiTextField:{
              styleOverrides: {
                root:{
                  marginTop: 0,
                  borderRadius: '40px',
                }
              }
            },
          
            MuiFormControlLabel:{
              styleOverrides: {
                root:{
                  fontWeight: 600
                }
              }
            },
            MuiCardContent:{
              styleOverrides: {
                root:{
                  "&:last-child": {
                    paddingBottom: 5,
                  }
                }
              }
            }
        }
          }),
        [darkMode],
      );



      const toggleDarkMode = (checked) => {
        if (checked === null) {
          setDarkMode(prefersDarkMode);
        }

        else setDarkMode(checked);
      };

    const router = createBrowserRouter([
        {
          path: "/",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<Device/>} width="30rem" toggleDarkMode={toggleDarkMode}/>,
        },
        {
          path: "/device",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<Device/>} width="30rem" toggleDarkMode={toggleDarkMode}/>,
        },
        {
          path: "/login",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<SignIn/>} width="30rem" toggleDarkMode={toggleDarkMode}/>,
        },
        {
          path: "/register",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<SignUp/>} width="30rem" toggleDarkMode={toggleDarkMode}/>,
        },
        {
          path: "/login",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<SignIn/>} width="30rem" toggleDarkMode={toggleDarkMode}/>,
        },
        {
          path: "/forgot",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<Forgot/>} width="30rem" toggleDarkMode={toggleDarkMode}/>,
        },
        {
          path: "/desktop",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<Desktop/>} width="80rem" toggleDarkMode={toggleDarkMode}/>,
        },
        {
          path: "/static",
          element: <AppStatic />,
        },
        {
          path: "/results",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<Results/>} width="90vw" toggleDarkMode={toggleDarkMode}/>,
        },
        {
          path: "/admin",
          element: <BaseApp redirectURL="" isAdmin={true} Child={<Admin/>} width="98vw" toggleDarkMode={toggleDarkMode}/>,
        },
        {
          path: "/mod",
          element: <BaseApp redirectURL="mod" isAdmin={true} Child={<Mod/>} width="80rem" toggleDarkMode={toggleDarkMode}/>,
        },
        {
          path: "/quiz",
          element: <BaseApp redirectURL="quiz" isAdmin={true} Child={<Quiz/>} width="80rem" toggleDarkMode={toggleDarkMode}/>,
        },
      
        {
          path: "/*",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<SignIn/>} width="30rem" toggleDarkMode={toggleDarkMode}/>,
        },
      ]);

    const notistackRef = React.useRef();
    const onClickDismiss = key => () => { 
      notistackRef.current.closeSnackbar(key);
    }
      

  return (
    <ThemeProvider theme={theme}>
      <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" rel="stylesheet" />
      <SnackbarProvider autoHideDuration={5000} ref={notistackRef} action={(key) => (
        <IconButton onClick={onClickDismiss(key)}><CloseIcon fontSize="inherit" /></IconButton>)}>
        <CssBaseline />
        <RouterProvider router={router} />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
