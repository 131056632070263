import { Grid, Paper } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ConectadoIcon from "img/ConectadoIcon.png";
import ConectadoWhiteIcon from "img/ConectadoIconWhite.png";
import PropTypes from 'prop-types';

export default function AppIcon(props){
    const isDarkTheme = useTheme().palette.mode === 'dark';
return(
    <Grid
    component={Paper}
    elevation={1}
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{
    width: props.width,
    height: props.height,
    marginBottom: "1rem",
    boxShadow: "5px 10px 20px rgba(1,1,1,.1)",
    borderRadius: "15px",
    }}>
        <div
        alt="Conectado"
        style={{
            backgroundImage: `url(${(isDarkTheme ? ConectadoWhiteIcon : ConectadoIcon)})`,
            width: "75%",
            height: "75%",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        }}
        ></div>
    </Grid>
    );
}

AppIcon.propTypes = {
    height: PropTypes.any.isRequired,
    width: PropTypes.any.isRequired,
};

AppIcon.defaultProps = {
    height: "6rem",
    width: "6rem",
};