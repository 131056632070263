import { useState } from 'react';

export default function useRedirect() {
  const getRedirect = () => {
    
    return localStorage.getItem('redirect');
  }

  const [redirect, setRedirect] = useState(getRedirect());

  const saveRedirect = userRedirect => {
    localStorage.setItem('redirect', userRedirect);
    setRedirect(userRedirect);
  };


  return {
    setRedirect: saveRedirect,
    redirect
  }
}
