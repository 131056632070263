import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export default function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      sx={{fontSize:12 }}
    >
      {"Copyright © "}
      <Typography component={Link} color="inherit" to="https://conectado.com/" sx={{fontSize:12}}>
        Conectado
      </Typography>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}