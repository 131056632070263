import * as React from "react";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Cookies from "universal-cookie";
import { LightMode, DarkMode } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Button, { ButtonProps } from '@mui/material/Button';
import theme from "./theme";
import { styled } from '@mui/material/styles';
import { NeuButton } from "./NeuButton";
import { useTheme } from "@mui/material/styles";

export default function ThemeSwitcherComponent(props, useDark) {
  const [state, setState] = useState(props);
  const cookies = new Cookies();
  const [checked, setChecked] = useState(cookies.get("theme") === "light");
  const theme = useTheme();
  
  React.useEffect(() => {
    setChecked(cookies.get("theme") ? cookies.get("theme") === "light" : useDark ? "dark" : "light");
    setState({
      themeChanger: state.themeChanger,
    });
  }, []);
  
  const handleSwitch = (_e) => {
    setState({ ...state});
    cookies.set("theme", checked ? "dark" : "light", {
      path: "/",
      secure: true,
      sameSite: "Lax",
    });
    state.themeChanger(checked);
    setChecked(!checked);
  };

  return (
    <>
      <NeuButton variant="contained" aria-label="theme-switch" onClick={(e) => handleSwitch(e)} sx={({position:"fixed", bottom:"1rem", left:"1rem"})}>
        {!checked ? <LightMode sx={{ color: theme.palette.action.active }} /> : <DarkMode sx={{ color: theme.palette.action.active }} />}
      </NeuButton>
    </>
  );
}
