import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Grid, Paper } from "@mui/material";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import PropTypes from 'prop-types';
import Icon from '@mui/material/Icon';


function renderRow(props) {

    const GUTTER_SIZE = 5;
    const { data, index, style } = props;
    const item = data[index];
    return (
      
        <ListItem key={index} component={Paper} style={{
            ...style,
            height: style.height - GUTTER_SIZE
          }}>
        <ListItemIcon>
        <Icon className="material-symbols-rounded" sx={{fontVariationSettings:``}}>{item.icon != "" ? item.icon : ""}</Icon>
        </ListItemIcon>
            <ListItemText primary={item.title} secondary={item.data}/>
        </ListItem>
        
    );
  }

  export default function VirtualizedList(props) {
    return (

      <Box
        sx={{ width: props.width, height: (props.itemSize * props.items.length) > props.height ? props.height : (props.itemSize * props.items.length), bgcolor: 'background.paper'}}
      >
      
        <FixedSizeList
        height={props.height}
        width={props.width}
        itemSize={props.itemSize}
        itemData={props.items}
        itemCount={props.items.length}
        overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
    </Box>
    );
}

  
VirtualizedList.propTypes = {
    rowHeight: PropTypes.number.isRequired,
    rowWidth: PropTypes.any.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.any.isRequired,
    items: PropTypes.array.isRequired,
    itemSize: PropTypes.number.isRequired,
}

VirtualizedList.defaultProps = {
    height: 100,
    width: "100%",
    rowHeight: 100,
    rowWidth: "100%",
    itemSize: 100,
    items: []
}