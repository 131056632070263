import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PublicIcon from '@mui/icons-material/Public';
import QuizIcon from '@mui/icons-material/Quiz';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ClearSession from 'Helpers/ClearSession';
import * as React from 'react';
import { Link } from 'react-router-dom';

export default function TemporaryDrawer(currentTab) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const tabs = ['Conectadoverse', 'Admin', 'Mod', 'Quiz'];
  const redirects = ['../', '../admin', '../mod', '../quiz'];
  const tabIcons = [<PublicIcon/>, <AdminPanelSettingsIcon/>, <ViewCarouselIcon/>, <QuizIcon/>]


  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {tabs.map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component={Link} to={redirects[index]}>
              <ListItemIcon>
                {tabIcons[index]}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <ListItem key="Logout" disablePadding>
            <ListItemButton onClick={ClearSession}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
    </Box>
  );

  return (
    <Grid container justifyContent="flex-end">
      <IconButton onClick={toggleDrawer(true)} ><MenuIcon/></IconButton>
      <SwipeableDrawer open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)} anchor="right">
        {DrawerList}
      </SwipeableDrawer>
    </Grid>
  );
}