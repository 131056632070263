import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import TemporaryDrawer from "Drawers/AdminDrawer";
import APIRequest from 'Helpers/ApiRequest';
import theme from "Helpers/theme";
import PropTypes from "prop-types";
import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(question, id, timeCreated, answers) {
  return { question, id, timeCreated, answers };
}


function encodeURI(details) {
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
}

const fileTypes = ["JPG", "PNG"];
const cookies = new Cookies();

async function getData() {
  try {
    var newRows = [];
    var questions = [];
    var questionsBody = encodeURI({ action: "questions_list" });

    const response = await Promise.all([
      fetch("https://conectadoverse.com/api/api.php", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: questionsBody,
      }),
    ]);
    let questionsData = await response[0].json();
    // let questionsData = testQuestions;
    console.log(questionsData);

    if (questionsData.status == false) {
      console.log("Failed");
      if(location.hostname !== "localhost"){
        // ClearSession();
      }
    }
    if (questionsData.status == true) {
      questionsData.data.questions.forEach((ques) => {
        if (!questions.includes(ques.question)) {
          newRows.push(
            createData(
              ques.question,
              ques.id,
              dayjs(Date(ques.time_created)).format("D MMM YY"),
              ques.answers
            )
          );
        }
      });
    }
  } catch (err) {
    // setOpen(true);
    setMessage(err, "error");
    console.log(err);
  } finally {
    return newRows;
  }
}

export default function Quiz() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState("");


  async function getNewRowsAsync() {
    const row = await getData();
    setRows(row.sort((a, b) => (b.status < a.status ? -1 : 1)));
  }

  async function getNewRowsAsyncReturn() {
    const row = await getData();
    return row.sort((a, b) => (b.status < a.status ? -1 : 1))
  }


  React.useEffect(() => {
    getNewRowsAsync();
  }, []);


  function Row2(props) {
    const { row } = props;
    const [openRow, setOpenRow] = React.useState(false);
    const [addRow, setAddRow] = React.useState(false);
  
    const [checked, setChecked] = React.useState(false);
    const [name, setName] = React.useState('');
  
    const handleChecked = (event) => {
      setChecked(event.target.checked);
    };
  
    const newChange = async (event) => {
      event.preventDefault();
      var req = await APIRequest({action: "questions_create_answer", question_id:row.id, answer:name, is_correct:checked ? 1:0});
      if (req.status == true){
        setChecked(false);
        setName("");
        setAddRow(false);
        setError(false);
        setHelperText('');
        getNewRowsAsync();
      }
      else{
        setError(true);
        setHelperText(req.message)
        console.log(req.message);
      }
    }

    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    const [errorLevel, setErrorLevel] = React.useState('error');

    const handleErrorChange = (event) => {
      setError(false);
    }
  
    const handleAnswerDelete = async (event, questionId, answerId) => {
      event.preventDefault();
      var req = await APIRequest({action: "questions_delete_answer", question_id:questionId, question_answer_id:answerId});
      if (req.status == true){
        setChecked(false);
        setName("");
        setAddRow(false);
        setError(true);
        setHelperText('Success');
        setErrorLevel('success');
        getNewRowsAsync();
      }
      else{
        setError(true);
        setHelperText(req.message)
        setErrorLevel('error')
      }
    };
    const handleQuestionDelete = async (event, questionId, answerId) => {
      event.preventDefault();
      var req = await APIRequest({action: "questions_delete", question_id:questionId});
      if (req.status == true){
        setChecked(false);
        setName("");
        setAddRow(false);
        setError(true);
        setHelperText('Success');
        setErrorLevel('success');
        getNewRowsAsync();
      }
      else{
        setError(true);
        setHelperText(req.message)
        setErrorLevel('error')
      }
    };

  
    return (
      <React.Fragment sx={{backgroundColor:"background.paper"}}>

            <Snackbar open={error} autoHideDuration={6000} onClose={handleErrorChange}>
        <Alert
          onClose={handleErrorChange}
          severity={errorLevel}
          sx={{ width: '100%' }}
        >
          {helperText}
        </Alert>
      </Snackbar>
        <TableRow sx={{ "& > *": { borderBottom: "unset", backgroundColor: theme.palette.action.selected}}}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenRow(!openRow)}
              disabled="true"
            >
              {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.question}
          </TableCell>
          <TableCell style={{}} align="right">
            {row.id}
          </TableCell>
          <TableCell style={{}} align="center">
            {row.timeCreated}
          </TableCell>
          <TableCell style={{}} align="right">
            <IconButton onClick={(e) => handleQuestionDelete(e, row.id)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Box in={openRow} timeout="auto"  >
              <Box sx={{ margin: 1 }}>
                <Table aria-label="answers">
                  <TableHead>
                    <TableRow sx={{backgroundColor: theme.palette.action.hover}}>
                      <TableCell>Answer</TableCell>
                      <TableCell align="right">Correct</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.answers.map((answerRow) => (
                      <TableRow key={answerRow.id}>
                        <TableCell component="th" scope="row" sx={{ width: "100%"}}>
                          {answerRow.answer}
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox checked={answerRow.is_correct ? true: false} disabled="true"
                          ></Checkbox>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={(e) =>
                              handleAnswerDelete(e, row.id, answerRow.id)
                            }
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {addRow && (
                      
                        <TableRow>
                          <TableCell>
                            <TextField
                              label="Answer"
                              variant="standard"
                              multiline
                              value={name}
                              onChange={(event) => {setName(event.target.value);}}
                              sx={{ width: "100%"}}
                            />
                          </TableCell>
                          
                          <TableCell align="right">
                            <Checkbox checked={checked} onChange={handleChecked}> </Checkbox>
                          </TableCell>
                          <TableCell align="right">
                            <Button onClick={newChange}>
                              Add Answer
                            </Button>
                      </TableCell>
                        </TableRow>
                      
                    )}
                    {!addRow &&
                    <TableRow>
                      <TableCell component="th" colSpan={4} align="center">
                        <Button onClick={(e) => setAddRow(true)}>
                        New Answer
                        </Button>
                      </TableCell>
                    </TableRow>
                  }
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let [mess, setMessage] = React.useState("Unknown Error", "success");
  const [searchParams, setSearchParams] = useSearchParams();
  let [open, setOpen] = React.useState(false);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const addQuestion = (props) => {};


  var loc = searchParams.get("plc") ? "/?plc=" + searchParams.get("plc") : "";

  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  const [addQues, setAddQues] = React.useState(false);
  const [quesName, setQuesName] = React.useState('');

  const [quesError, setQuesError] = React.useState(false);
  const [quesHelperText, setQuesHelperText] = React.useState('');
  const [quesErrorLevel, setQuesErrorLevel] = React.useState('error');

  const handleQuesErrorChange = (event) => {
    setQuesError(false);
  }
  
  const newQuestion = async (event) => {
    event.preventDefault();
    var req = await APIRequest({action: "questions_create", question:quesName});
    if (req.status == true){
      setQuesHelperText('Success');
      setQuesErrorLevel('success')
      setQuesName("");
      setAddQues(false);
      setQuesError(true);
      getNewRowsAsync();
    }
    else{
      setQuesError(true);
      setQuesErrorLevel('error')
      setQuesHelperText(req.message)
      console.log(req.message);
    }
  }


  return (
    <Box>
      <TemporaryDrawer/>
      <Container component="main" maxWidth="xm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >

          <Typography component="h1" variant="h4" sx={{fontWeight: 700}}>
            Quiz
          </Typography>

          <Box
            component="form"
            id="loginForm"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
            <Typography
              component="h2"
              variant="h7"
              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              Questions
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="Quiz Table">
              <TableHead>
                    <TableRow >
                    <TableCell></TableCell>
                      <TableCell>Question</TableCell>
                      <TableCell align="right">ID</TableCell>
                      <TableCell align="right">Date</TableCell>
                    </TableRow>
                  </TableHead>
                {rows && (
                  <TableBody>
                    {(rowsPerPage > 0
                      ? rows.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : rows
                    ).map((row) => (
                      <Row2 row={row} key={row.id}></Row2>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
                <TableFooter>
                {addQues && (
                      
                      <TableRow>
                      <TableCell align="right">
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Question"
                            variant="standard"
                            multiline
                            value={quesName}
                            onChange={(event) => {setQuesName(event.target.value);}}
                            sx={{ width: "100%"}}
                          />
                        </TableCell>
                        
                        <TableCell align="right">
                        </TableCell>
                        <TableCell align="right">
                        </TableCell>
                        <TableCell align="right">
                          <Button onClick={newQuestion}>
                          Add Question
                          </Button>
                    </TableCell>
                  
                      </TableRow>
                    
                  )}

                  {!addQues &&
                  <TableRow>
                  <TableCell component="th" colSpan={5} align="center">
                    <Button onClick={(e) => setAddQues(true)}>
                      New Question
                    </Button>
                  </TableCell>
                  </TableRow>
                }
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={3}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{ color: "text.secondary", align: "center", fontWeight: 600 }}
            >
              Want to go back to Admin?{" "}
              {
                <Typography
                  component={Link}
                  to={`/admin${loc}`}
                  variant="contained"
                  color="primary"
                  sx={{ textDecoration: "none" }}
                >
                  Admin
                </Typography>
              }
            </Typography>
          </Box>
        </Box>
      </Container>
      <Snackbar open={quesError} autoHideDuration={6000} onClose={handleQuesErrorChange}>
        <Alert
          onClose={handleQuesErrorChange}
          severity={quesErrorLevel}
          sx={{ width: '100%' }}
        >
          {quesHelperText}
        </Alert>
      </Snackbar>
    </Box>
  );
}
